import React from "react"
import Layout from "../components/layout"
import {
  HeaderSecondary,
  ContactSection,
  DecorationSection,
  ServicesSection,
} from "../components/utility"

import { useStaticQuery, graphql } from "gatsby"

export default function AboutUs() {
  const data = useStaticQuery(graphql`
    query {
      aboutus: file(relativePath: { eq: "decorative/conversation.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const About = [
    data.aboutus.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.38),
      rgba(0, 0, 0, 0.38))`,
  ].reverse()

  return (
    <Layout>
      <HeaderSecondary
        fluid={About}
        title="Helping your"
        subtitle="business"
        smalltitle="thrive"
      />
      <ServicesSection />
      <DecorationSection />
      <ContactSection />
    </Layout>
  )
}
